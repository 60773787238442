<template>
<div class="home">
  <div class="sec1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 pb-5 order-md-0 order-1">
          <h2 class="black">Ваш <span class="red">помощник</span><br> в Турции</h2>
          <p class="mt-3 mb-5">Туристические сервисы, помощь в оформлении документов и банковских карт, заказы в
            интернет-магазинах, услуги консультантов, гидов и переводчиков на одном веб-портале</p>
          <router-link :to="{name: 'FormPage', params:{formType: 'services'}}"  class="btn">Получить помощь <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
        </div>
        <div class="col-md-6 order-md-1 order-0">
          <img src="img/1-pick1.png" alt="">
        </div>

      </div>
    </div>
  </div>
  <div class="sec2 py-5">
    <div class="container py-4">
      <h3 class="black text-center my-4">Наши услуги</h3>
      <div class="row">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="slide_el d-flex flex-column justify-content-between">
            <div>
              <div class="pb-2">
                <div class=" s2_img_box d-flex justify-content-end"><div><img class="s2_img" src="img/2-1.png" alt=""></div></div>

                <div class=""><h4 class="black">Помощь туристам</h4></div>
              </div>
              <p>экскурсии, трансфер, шопинг-консультации, услуги гида.
              </p>
            </div>
            <div>
              <router-link class="red" :to="{name: 'Services'}">Подробнее <i><img src="img/arrow-red-24-px.svg" alt=""></i></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="slide_el d-flex flex-column justify-content-between">
            <div>
              <div class="pb-2">
                <div class="s2_img_box d-flex justify-content-end"><div><img class="s2_img" src="img/2-2.png" alt=""></div></div>
                <div class=""><h4 class="black">Деловые услуги</h4></div>
              </div>
              <p>оформление ВНЖ и банковских счетов, прокат атомобиля, заказ билетов и многое другое </p>
            </div>
            <div>
              <router-link :to="{name: 'Services'}" class="red" >Подробнее <i><img src="img/arrow-red-24-px.svg" alt=""></i></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sec3 py-5">
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-4 col-md-6 px-5"><img src="img/3-1.png" alt=""></div>
        <div class="col-lg-8 col-md-6 d-flex flex-column justify-content-between">
          <div>
            <h3 class="black">Онлайн-шопинг</h3>
            <p>Делайте покупки в турецких интернет-магазинах, оплачивая российской банковской картой
            </p>
            <ul class="fw-bold list">
              <li>Оформим заказ и доставим по вашему адресу в Турции
              </li>
              <li> Онлайн-шопинг с доставкой в Россию</li>
            </ul>
          </div>
          <div>
            <router-link :to="{name: 'Purchases'}" class="btn">Подробнее о покупках<i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sec4 py-5">
    <div class="container py-4">
      <h3 class="black text-center">Почему мы?</h3>
      <p class="text-center pt-3">Оплата любой российской банковской картой (МИР, Visa, MasterCard)
        <br> на сайте или через мобильный терминал</p>
      <div class="row pt-5">
        <div class="col-md-4 col-sm-6">
          <div class="s4_slide px-4 text-center">
            <div class="img__block d-flex justify-content-center align-items-center"> <img src="img/4-1.png" alt=""></div>
            <p class="black fw-bold py-4"> Опытные русскоговорящие специалисты </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="s4_slide px-4 text-center">
            <div class="img__block d-flex justify-content-center align-items-center"> <img  src="img/4-2.png" alt=""></div>
            <p class="black fw-bold py-4"> Прозрачные цены - стоимость огововаривается заранее </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="s4_slide px-4 text-center">
            <div class="img__block d-flex justify-content-center align-items-center"> <img class="cust1" src="img/4-3.png" alt=""></div>
            <p class="black fw-bold py-4"> Служба поддержки онлайн 24/7 </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Home",
  mounted(){
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
</script>

<style scoped>

</style>
